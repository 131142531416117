<template>
  <div class="asset-wrap">
    <YouTubeContainer
      class="asset video-asset"
      v-if="asset === 'video'"
      :videoCode="videoCode"
      :startTime="parseInt(mission.videoTime || 0)"
      autoplay
      :onPlayerPlay="onPlayYoutubeVideo"
      :onPlayerPaused="onPausedYoutubeVideo"
    />
    <!-- Image -->
    <img
      class="asset image-asset"
      v-else-if="asset === 'photo'"
      :src="mission.photo"
    />
    <!-- Audio -->
    <div class="asset audio-player-asset" v-else-if="asset === 'audio'">
      <v-layout column justify-center fill-height>
        <v-flex grow style="position: relative">
          <div v-if="user.role == 'facilitator'">
            Answer: {{ mission.notes }}
          </div>

          <div class="equalizer-placeholder"></div>
        </v-flex>
        <v-flex shrink>
          <vue-plyr ref="audioPlayer">
            <audio
              id="audioPlayer"
              controls
              data-plyr-config='{ "controls": ["play", "volume"] }'
            >
              <source :src="mission.audio" type="audio/mp3" />
            </audio>
          </vue-plyr>
        </v-flex>
      </v-layout>
    </div>
    <!-- Canvas -->
    <v-flex d-flex fill-height text-xs-center v-else-if="asset === 'drawing'">
      <MissionContainer :mode="'explain'" :key="`drawing-info-${missionID}`">
        <DrawingTextInput :mode="mode" />
      </MissionContainer>
    </v-flex>
    <!-- ??? -->
    <v-flex
      v-else-if="asset === 'playImage'"
      class="grey lighten-3"
      height="500"
    >
      <v-card v-if="!!gameStatusPlay" flat d-inline>
        <span id="play-caption">
          {{ gameStatusPlay.username }} on {{ gameStatusPlay.teamname }}: "{{
            gameStatusPlay.correct.caption
          }}"
        </span>
        <img
          v-if="!!gameStatusPlay.correct"
          :src="gameStatusPlay.correct.image"
          width="100%"
        />
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { EventBus } from "@/Eventbus"
import { mapState, mapActions, mapGetters } from "vuex"
const YouTubeContainer = () =>
  import("@/components/GroupTeams/Common/YouTubeContainer.vue")
const DrawingTextInput = () =>
  import("@/components/GroupTeams/Common/Games/DrawingTextInput.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")

export default {
  name: "AssetMapper",
  components: {
    YouTubeContainer,
    DrawingTextInput,
    MissionContainer
  },
  props: {
    mode: {
      type: String,
      default: null
    },
    drawing: Boolean
  },
  data() {
    return {
      asset: null
    }
  },
  watch: {
    mediaToShow(newValue) {
      this.asset = newValue
    },
    "$store.state.group.missionAudioState": {
      handler(newValue) {
        if (!newValue) return console.warn("The prop playing is not available")
        const { playing, seekTime } = newValue
        window.setTimeout(() => {
          if (this.user.role === "facilitator") return
          const playerElement = document.getElementById("audioPlayer")
          try {
            if (playerElement) {
              if (playing) {
                playerElement.currentTime = seekTime
                playerElement.play()
              } else {
                playerElement.pause()
              }
            }
          } catch (e) {
            console.log(e.message)
          }
        }, 0.5 * 1000)
      },
      immediate: true
    },
    "$store.state.group.missionVideoState": {
      handler: function (newValue) {
        if (!newValue) return console.warn("The prop playing is not available")
        const { playing } = newValue
        window.setTimeout(() => {
          if (this.user.role === "facilitator") return

          if (playing) {
            EventBus.$emit("playYoutubeVideo", newValue)
          } else {
            EventBus.$emit("pauseYoutubeVideo", newValue)
          }
        }, 0.5 * 1000)
      },
      immediate: true
    },
    volume: {
      handler: function (volume) {
        this.$nextTick(() => {
          const player = document.getElementById("audioPlayer")
          if (player) {
            player.volume = volume
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
    // keep the current asset type locally
    this.asset = this.mediaToShow
    this.$nextTick(() => {
      if (this.player) {
        if (this.user.role !== "facilitator") return
        this.player.on("play", this.onPlayerPlay)
        this.player.on("pause", this.onPlayerPause)
      }
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.off("play", this.onPlayerPlay)
      this.player.off("pause", this.onPlayerPause)
    }
  },
  computed: {
    ...mapState("group", ["missionAudioState"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("soundeffect", ["volume"]),
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters({
      missionID: "currentMission",
      mission: "getCurrentMission",
      gameStatus: "gameStatus"
    }),
    gameStatusPlay() {
      return this.gameStatus ? this.gameStatus.play : null
    },
    videoCode() {
      try {
        return this.mission.youtube
      } catch (e) {
        console.log(e.message)
      }
      return null
    },
    mediaToShow() {
      if (this.roomID) {
        if (this.mission.photo) {
          return "photo"
        } else if (this.mission.audio) {
          return "audio"
        } else if (this.mission.youtube) {
          return "video"
        } else {
          return "none"
        }
      } else {
        if (
          this.gameStatus.media === "none" ||
          this.gameStatus.media === "facilitator"
        ) {
          return null
        } else {
          return this.drawing ? "drawing" : this.$store.getters.gameStatus.media
        }
      }
    },
    player() {
      if (this.$refs.audioPlayer) {
        return this.$refs.audioPlayer.player
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions("group", [
      "toggleMissionAudioState",
      "toggleMissionVideoState"
    ]),
    onPlayerPlay() {
      this.toggleMissionAudioState({
        playing: true,
        seekTime: Math.floor(this.player.currentTime)
      })
    },
    onPlayerPause() {
      this.toggleMissionAudioState({
        playing: false,
        seekTime: Math.floor(this.player.currentTime)
      })
    },
    onPlayYoutubeVideo(player) {
      if (this.user.role !== "facilitator") return

      this.toggleMissionVideoState({
        playing: true,
        seekTime: Math.floor(player.getCurrentTime())
      })
    },
    onPausedYoutubeVideo(player) {
      if (this.user.role !== "facilitator") return

      this.toggleMissionVideoState({
        playing: false,
        seekTime: Math.floor(player.getCurrentTime())
      })
    }
  }
}
</script>

<style scoped lang="scss">
.equalizer-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/equalizer-placeholder2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.asset-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.asset-wrap::after {
  content: "";
  width: 0px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.audio-player-asset {
  height: 100%;
  width: 100%;
  max-width: 330px !important;
  display: inline-flex;
  flex-direction: column;
}

.audio-player-asset > div {
  width: 100%;
}

.audio-player-asset .plyr {
  width: 100%;
}

.plyr--audio .plyr__controls {
  padding: 0 !important;
}

.asset {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  background-color: $color-white;
}

.video-asset {
  display: inline-flex;
}

.image-asset {
  max-width: 100%;
  max-height: 100%;
}
</style>
