var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asset-wrap" },
    [
      _vm.asset === "video"
        ? _c("YouTubeContainer", {
            staticClass: "asset video-asset",
            attrs: {
              videoCode: _vm.videoCode,
              startTime: parseInt(_vm.mission.videoTime || 0),
              autoplay: "",
              onPlayerPlay: _vm.onPlayYoutubeVideo,
              onPlayerPaused: _vm.onPausedYoutubeVideo
            }
          })
        : _vm.asset === "photo"
        ? _c("img", {
            staticClass: "asset image-asset",
            attrs: { src: _vm.mission.photo }
          })
        : _vm.asset === "audio"
        ? _c(
            "div",
            { staticClass: "asset audio-player-asset" },
            [
              _c(
                "v-layout",
                {
                  attrs: { column: "", "justify-center": "", "fill-height": "" }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { grow: "" }
                    },
                    [
                      _vm.user.role == "facilitator"
                        ? _c("div", [
                            _vm._v(
                              " Answer: " + _vm._s(_vm.mission.notes) + " "
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "equalizer-placeholder" })
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("vue-plyr", { ref: "audioPlayer" }, [
                        _c(
                          "audio",
                          {
                            attrs: {
                              id: "audioPlayer",
                              controls: "",
                              "data-plyr-config":
                                '{ "controls": ["play", "volume"] }'
                            }
                          },
                          [
                            _c("source", {
                              attrs: {
                                src: _vm.mission.audio,
                                type: "audio/mp3"
                              }
                            })
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.asset === "drawing"
        ? _c(
            "v-flex",
            {
              attrs: { "d-flex": "", "fill-height": "", "text-xs-center": "" }
            },
            [
              _c(
                "MissionContainer",
                {
                  key: "drawing-info-" + _vm.missionID,
                  attrs: { mode: "explain" }
                },
                [_c("DrawingTextInput", { attrs: { mode: _vm.mode } })],
                1
              )
            ],
            1
          )
        : _vm.asset === "playImage"
        ? _c(
            "v-flex",
            { staticClass: "grey lighten-3", attrs: { height: "500" } },
            [
              !!_vm.gameStatusPlay
                ? _c("v-card", { attrs: { flat: "", "d-inline": "" } }, [
                    _c("span", { attrs: { id: "play-caption" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.gameStatusPlay.username) +
                          " on " +
                          _vm._s(_vm.gameStatusPlay.teamname) +
                          ': "' +
                          _vm._s(_vm.gameStatusPlay.correct.caption) +
                          '" '
                      )
                    ]),
                    !!_vm.gameStatusPlay.correct
                      ? _c("img", {
                          attrs: {
                            src: _vm.gameStatusPlay.correct.image,
                            width: "100%"
                          }
                        })
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }